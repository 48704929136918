import React from "react"
import Body from '../components/layout/default/main'
import WebappsList from "../components/widgets/webappslist/webappslist"

export default () => (
    <Body>
        <h1>Kadebom! Webapps</h1>
        <WebappsList />
    </Body>
)
